<script setup lang="ts">
import type { AsfColorProps } from '@ui/types'

const props = withDefaults(defineProps<AsfColorProps>(), {
  size: '6'
})
const slots = useSlots()
const { sizeProps } = useSize(props)
const colorStyle = computed(() => {
  if (slots.default) return ''

  return {
    '--color-background': props.color
  }
})
const colorSizes = computed(() => ({
  '--color-height': sizeProps.value.height,
  '--color-width': sizeProps.value.width
}))
</script>
<template>
  <span class="asf-color" :style="[colorStyle, colorSizes]">
    <span class="asf-color__inner">
      <slot />
    </span>
  </span>
</template>
<style lang="postcss">
@import '@components/atoms/Color/Color.css';
</style>
