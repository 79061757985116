import type { TranslateResult } from 'vue-i18n'
import type { RouteLocationRaw } from 'vue-router'
import type { AgnosticImage, AgnosticPriceValue, AgnosticPromotion } from 'shared/types'
import type {
  AsfCustomValidationMessages,
  AsfCustomValidationRules,
  AsfFormElementProps,
  AsfHorizontalPosition,
  AsfInputTypes,
  AsfPosition,
  AsfTransitions
} from '@ui/types'
import type { AsfFlagSet, AsfIconSet, AsfPaymentIconSet, AsfSpriteIconsSet } from '@ui/icons'
import type { AsfSizes } from '#tailwindConfig'

type AsfAddressGroup = {
  id?: string
  firstName?: string
  lastName?: string
  address1?: string
  address2?: string
  country?: string
  state?: string
  postalCode?: string
  city?: string
  phone?: string
}
type AsfBadgeConfig = {
  background?: string
  color?: string
}
type AsfHeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
type AsfOptionProps = {
  disabled?: boolean
  label?: TranslateResult | string
  selected?: boolean
  value: string
}
type AsfTableItem = {
  accent: boolean
  data: any[]
}
type AsfTableData = {
  [s: string]: AsfTableItem
}
type AsfVideoThumbnailQuality = 'default' | 'medium' | 'high' | 'standard' | 'maxres'

export type AsfAlertTypes = 'default' | 'info' | 'success' | 'warning' | 'error' | 'error-outline' | 'misstep'
export type AsfPromotionProps = Omit<AgnosticPromotion, 'id'>
export type AsfVariationSwatchType = 'brand' | 'color' | 'size'
export type AsfWrapperType = 'section' | 'header'
export type AsfWrapperTag = 'div' | 'article' | 'footer' | 'header' | 'main' | 'nav' | 'section' | 'form'
export type AsfVideoProvider = 'youtube' | 'vimeo' | 'dailymotion'

export enum AsfVariationColors {
  WHITE = '#ffffff',
  RED = '#ff0000',
  BLACK = '#000000',
  YELLOW = '#f5a623',
  BLUE = '#3265e5',
  GREEN = '#2e7d32',
  PURPLE = '#ffdbea',
  VIOLET = '#ee82ee',
  ORANGE = '#ff8316',
  BROWN = '#805a46',
  PINK = '#FFC0CB',
  GREY = '#808080',
  GOLD = '#FFD700'
}

export interface AsfAddressGroupProps {
  address: AsfAddressGroup
}
export interface AsfAlertProps {
  /**
   * Defines visual state of the component
   * @values default, info, success, warning, error, error-outline, misstep
   */
  appearance: AsfAlertTypes
  /**
   * Text alignment
   * @values left, center, right
   */
  alignment?: AsfHorizontalPosition
}
export interface AsfBadgeProps {
  appearance?: 'circle' | 'square'
  config?: AsfBadgeConfig
}
export interface AsfButtonProps {
  fullWidth?: boolean
  disabled?: boolean
  title?: string
  ariaLabel?: string
}
export interface AsfCheckboxProps extends AsfFormElementProps {
  labelAriaHidden?: boolean
  checked?: string[] | boolean
  customMessages?: AsfCustomValidationMessages
  id?: string
  validationRules?: AsfCustomValidationRules
  value?: string | boolean
  focusable?: boolean
  optionCount?: number
}
export interface AsfChevronProps {
  appearance?: Exclude<AsfPosition, 'center'>
}
export interface AsfColorProps {
  color: string
  size?: AsfSizes
}
export interface AsfDividerProps {
  position?: AsfHorizontalPosition
}
export interface AsfHeadingProps {
  tag?: AsfHeadingVariant
  appearance?: AsfHeadingVariant | ''
  styles?: Record<string, string | undefined>
}
export interface AsfIconProps {
  /** ID for the component, will be used in the `aria-labelledby`, so beware to make it unique */
  id?: string
  /** Icon SVG name, should match the names defined in AsfIconSet | AsfFlagSet | AsfPaymentIconSet types */
  name?: AsfIconSet | AsfFlagSet | AsfPaymentIconSet
  /** Size of the icon, value generated based on Tailwind config. should match AsfSizes type */
  size?: AsfSizes
  /**
   * Title of the icon.
   * Beware that omitting a title will leave the icon invisible to screen readers.
   * If included, screen readers will read and treat the icon like an image with alt text.
   */
  title?: TranslateResult | string
  preserveAspectRatio?: string
}
export interface AsfIconSpriteProps {
  name: AsfSpriteIconsSet
  title?: string
}
export interface AsfImageProps extends AgnosticImage {
  /**
   * One or more strings separated by commas,
   * indicating possible image sources for the user agent to use
   */
  srcset?: string
  /**
   * One or more strings separated by commas, indicating a set of source sizes
   * For example, (max-height: 500px) 1000px
   */
  sizes?: string
  /** Determines the type of image loading, lazy loading or not */
  lazy?: boolean
  /** Prepare component to use width-to-height aspect ratio. The default ratio is 1:1. */
  adaptive?: boolean
  width?: string | number
  height?: string | number
  /** Determines fetchpriority hint for browser, if images is more important set this to high */
  fetchPriority?: string
}
export interface AsfInputProps extends AsfFormElementProps {
  customMessages?: AsfCustomValidationMessages
  enablePasswordStrength?: boolean
  format?: string
  maxlength?: number
  minlength?: number
  pattern?: string
  placeholder?: TranslateResult
  readonly?: boolean
  showLabel?: boolean
  type?: AsfInputTypes
  validationRules?: AsfCustomValidationRules
  value?: string | number
  liveValueReplacer?: (str: string) => string
  inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
}
export interface AsfLinkProps {
  link?: RouteLocationRaw
  /** Disable the event(s) that can trigger the link navigation */
  prevent?: boolean
  /**
   * Native HTML tag, will be updated with `noreferrer` and `noopener` if
   * target="_blank" prop passed to the component
   */
  rel?: string
  /** Used for scroll to an anchor on the same page */
  isAnchor?: boolean
}
export interface AsfListProps {
  /** Defines the html tag for the List. */
  tag?: 'ul' | 'ol'
  /** Defines to render the decorative elements (margin, list-type ...) of the list */
  content?: boolean
}
export interface AsfLoaderProps {
  loading?: boolean
  updating?: boolean
}
export interface AsfOverlayProps {
  visible: boolean
  transition?: AsfTransitions
}
export interface AsfPriceProps {
  prices: AgnosticPriceValue[]
  large?: boolean
  zeroAsFree?: boolean
}
export interface AsfPropertyProps {
  label?: TranslateResult | string
  value?: TranslateResult | string
  tabindex?: number
}
export interface AsfRadioProps extends AsfFormElementProps {
  labelAriaHidden?: boolean
  checked?: string
  description?: string
  details?: string
  id?: string
  value?: string
  optionCount?: number
}
export interface AsfRangeProps {
  minRangeValue: number
  maxRangeValue: number
  step?: number
  connect?: boolean
  margin?: number
  minStartPosition?: number
  maxStartPosition?: number
  currency?: string
}
export interface AsfSelectProps extends AsfFormElementProps {
  customMessages?: AsfCustomValidationMessages
  options: AsfOptionProps[]
  placeholder?: TranslateResult
  showLabel?: boolean
  showIcon?: boolean
  validationRules?: AsfCustomValidationRules
  value?: string | number
  persistent?: boolean
}
export interface AsfSkeletonProps {
  appearance?: 'rectangle' | 'circle'
  duration?: number
  loading?: boolean
  size: AsfSizes
  tag?: AsfWrapperTag
  isImagePlaceholder?: boolean
}
export interface AsfTableProps {
  data: AsfTableData
  description?: string
  scrollable?: boolean
}
export interface AsfToggleProps extends AsfFormElementProps {
  checked?: string[] | boolean
  customMessages?: AsfCustomValidationMessages
  focusable?: boolean
  id?: string
  validationRules?: AsfCustomValidationRules
  value?: string | boolean
}
export interface AsfVariationSwatchProps {
  type: AsfVariationSwatchType
  name: TranslateResult | string
  value: string
  valueAttr?: string
  role?: string
  link?: RouteLocationRaw
  selected?: boolean
  disabled?: boolean
  hasOption?: boolean
  optionCount?: number
  productId?: string
  isAlwaysInteractive?: boolean
  noSwatchesHighlighter?: boolean
}
export interface AsfVideoWrapper {
  customThumbnail?: string
  isClicked?: boolean
  isInfoFetched?: boolean
  isLoadedOnce?: boolean
  isVideoFound?: boolean
  provider?: AsfVideoProvider
  showTitle?: boolean
  thumbnailQuality?: AsfVideoThumbnailQuality
  videoId: string
  videoTitle?: string
}
export interface AsfVideoProps {
  aspectRatio?: string
  autoplay?: boolean
  customThumbnail?: string
  customTitle?: string
  maxWidth?: string
  showTitle?: boolean
  src: string
  thumbnailQuality?: AsfVideoThumbnailQuality
}
export interface AsfWrapperProps {
  /** Defines html tag for wrapper. */
  tag?: AsfWrapperTag
  /** Defines styles for indentation and wrapper width. */
  type?: AsfWrapperType
}

export interface AsfLazyComponentProps {
  wrapperTag?: string
  isIntersected?: boolean
  idle?: boolean
  rootMargin?: string
  threshold?: number
}
